import * as React from "react"
import Layout from "../components/layout"
import HeroBanner from "../components/heroBanner"
import ProjectsFeatured from "../components/projectsFeatured"
import ProjectList from "../components/projectList"
import Seo from "../components/seo"
import Cta from "../components/cta"
import { graphql } from "gatsby"

const ProjectsPage = ({ data }) => {
  var page = data.projects
  return (
    <Layout>
      <HeroBanner title={page.heroTitle} image={page.heroImage} />
      <ProjectsFeatured title="Featured Projects" projects={data.featured.nodes} />
      <ProjectList title="More Projects" projects={data.moreProjects.edges} />
      <Cta />
    </Layout>
  )
}

export default ProjectsPage

export const Head = () => <Seo title="Projects" />

export const query = graphql`
  query ProjectsQuery {
    projects: datoCmsProjectPage {
      heroTitle
      heroImage {
        gatsbyImageData
      }
    }
    featured: allDatoCmsProject {
      nodes {
        id
        title
        slug
        category
        completed
        featureImage {
          gatsbyImageData
        }
      }
    }
    moreProjects:allDatoCmsSingleProject {
      edges {
        node {
          title
          developer
          client
          category
          builder
          completedDate
          description
          image {
            gatsbyImageData
          }
          location
        }
      }
    }
  }
`
