import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Spacer = ({ title, projects }) => {
  const TitleArea = () => {
    return (
      <div className="title flex mb40">
        <p className="m0 h4">{title}</p>
        <div className="mla op-50">Categories</div>
      </div>
    )
  }

  return (
    <div className="project--list p40 mb40">
      <TitleArea />
      <div className="project--index flex">
        <div className="gutter">
          <div className="ratio-2-1 sticky project--thumbnail">
             {projects.map((project, index) => {
              return (
                <div className='project-image' data-index={index}>
                  <GatsbyImage className="bg-image" image={project.node.image?.gatsbyImageData} alt={project.title} />
                </div>
              )
              })}
          </div>
        </div>
        <div className="index pl40 m-p0 medium m-small">
          {projects.map((project, index) => {
            return <ListItem active={(index===0 ? 'true':'')} key={index} index={index} project={project} />
          })}
        </div>
      </div>
    </div>
  )
}

const mouseOver = (index) => {
  document.querySelector('.index--item[data-active="true"]')?.setAttribute('data-active', '')
  document.querySelector('.project-image.active')?.classList.remove('active')
  document.querySelector('.project-image[data-index="'+index+'"]').classList.add('active')
  document.querySelector('.index--item[data-index="'+index+'"]').setAttribute('data-active', 'true')
}

const onClick = (index) => {
  var current = document.querySelector('.index--item[data-index="'+index+'"]');
  if (current.classList.contains("active")) {
    current.classList.remove("active")
    current.querySelector(".content").style.height = "0px"
  } else {
    if(document.querySelector('.index--item.active')) {
      document.querySelector('.index--item.active .content').style.height = "0px";
      document.querySelector('.index--item.active').classList.remove('active');
    }
    current.classList.add("active");
    current.querySelector(".content").style.height =
    current.querySelector(".content .inner").offsetHeight + "px"
  }
}

const ListItem = ({ project, active, index }) => {
  project = project.node;
  return (
    <div data-active={active} className="index--item project--accordion link" data-index={index} onClick={() => onClick(index)} onMouseOver={() => mouseOver(index)} onFocus={mouseOver} role='presentation'>
      <div className="header pt10 pb10 bb1 b-grey flex flex-wrap">
        <p className="m0 w-40 m-50">{project.title}</p>
        <p className="m0 w-20 m-hide">{project.category}</p>
        <p className="m0 w-20 m-50">{project.location}</p>
        <p className="m0 w-20 m-hide">
          <span className="pr20 w-100">{project.completedDate}</span>
        </p>
      </div>
      <div className='project--information content'>
        <div className='inner '>
          <div className='pt40 pb40 flex m-flex-column'>
            <div className='m-w100'>
              {( project.client ? 
                <div className='flex mb20'>
                  <p className='m0 w-50 m-op-50'>Client</p>
                  <p className='m0 w-50'>{project.client}</p>  
                </div> : ''
              )}
              {( project.builder ? 
                <div className='flex mb20'>
                  <p className='m0 w-50 m-op-50'>Builder</p>
                  <p className='m0 w-50'>{project.builder}</p>  
                </div> : ''
              )}
              {( project.developer ? 
                <div className='flex'>
                  <p className='m0 w-50 m-op-50'>Developer</p>
                  <p className='m0 w-50'>{project.developer}</p>  
                </div> : ''
              )}
            </div>
            <div className='w-60 m-w100 m-mt20'>
              {project.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spacer
